<template>
  <div v-if="inverter" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="inverter">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('manufacturer')"
            label-for="manufacturer"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="inverter.manufacturer"
              type="text"
              :placeholder="$t('manufacturer')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('type')"
            label-for="type"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="inverter.type" type="text" :placeholder="$t('type')" />
          </b-form-group>

          <legend>
            <strong>Dokumente</strong>
          </legend>

          <DropzoneUpload
            v-if="!isNew"
            :complete="isAttachmentUploadComplete"
            :parentId="inverter.id"
            :uploadCallback="uploadInverterAttachment"
            v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
          />

          <AttachmentList
            :attachments="inverter.attachments"
            v-on:attachment-list:delete="onDeleteInverterAttachment"
          ></AttachmentList>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Parameter</strong>
          </legend>
          <b-form-group
            horizontal
            :label="$t('nominalPower')"
            label-for="nominalPower"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="inverter.nominalPower"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" kW"
              locale="de-DE"
              :placeholder="$t('nominalPower')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('apparentPower')"
            label-for="apparentPower"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="inverter.apparentPower"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" kW"
              locale="de-DE"
              :placeholder="$t('apparentPower')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('activePower')"
            label-for="activePower"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="inverter.activePower"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" kW"
              locale="de-DE"
              :placeholder="$t('activePower')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('ratedCurrent')"
            label-for="ratedCurrent"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="inverter.ratedCurrent"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" A"
              locale="de-DE"
              :placeholder="$t('ratedCurrent')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('shortCircuitCurrent')"
            label-for="shortCircuitCurrent"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="inverter.shortCircuitCurrent"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" A"
              locale="de-DE"
              :placeholder="$t('shortCircuitCurrent')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('nominalDcPower')"
            label-for="nominalDcPower"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="inverter.nominalDcPower"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" kW"
              locale="de-DE"
              :placeholder="$t('nominalDcPower')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('allowedChargeDischargeCurrent')"
            label-for="allowedChargeDischargeCurrent"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="inverter.allowedChargeDischargeCurrent"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" A"
              locale="de-DE"
              :placeholder="$t('allowedChargeDischargeCurrent')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('nominalVoltage')"
            label-for="nominalVoltage"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="inverter.nominalVoltage"
              mode="decimal"
              :minFractionDigits="3"
              suffix=" V"
              locale="de-DE"
              :placeholder="$t('nominalVoltage')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('gridAndPlantProtectionIntegrated')"
            label-for="gridAndPlantProtectionIntegrated"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="inverter.gridAndPlantProtectionIntegrated"
              mode="decimal"
              :minFractionDigits="1"
              locale="de-DE"
              :placeholder="$t('gridAndPlantProtectionIntegrated')"
            />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('gridAndPlantProtectionType')"
            label-for="gridAndPlantProtectionType"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('gridAndPlantProtectionType')"
              v-model="inverter.gridAndPlantProtectionType"
              :options="getGridAndPlantProtectionTypes"
              :show-labels="false"
              :allow-empty="false"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
              <template slot="option" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="inverter"
            modelEditRoute="InverterEditPage"
            modelListRoute="InvertersPage"
            modelRouteParamName="inverterNumber"
            :updateCallback="updateInverter"
            :createCallback="createInverter"
            :deleteCallback="deleteInverter"
            :fetchCallback="fetchInverters"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import { yesNo } from '@/helpers/enums';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentList from '@/components/AttachmentList';
import InputNumber from 'primevue/inputnumber';
import Multiselect from 'vue-multiselect';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'InverterEditPage',
  components: {
    Widget,
    Breadcrumbs,
    DropzoneUpload,
    AttachmentList,
    InputNumber,
    Multiselect,
    ButtonBar,
  },
  props: {
    inverterNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      user: {},
      buttonsDisabled: false,
      isAttachmentUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getInverter', 'getInverterByNumber', 'getGridAndPlantProtectionTypes']),
    buttons() {
      return {
        showDeleteButton: this.inverter && this.inverter.number !== '_new' ? true : false,
      };
    },
    optionsYesNo() {
      return yesNo();
    },
    inverter() {
      return this.getInverterByNumber(this.number);
    },
    isNew() {
      return this.inverter.id === '_new';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('inverter'), route: { name: 'InvertersPage' } },
        { name: this.inverter.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'initInverter',
      'fetchInverters',
      'fetchInverterByNumber',
      'updateInverter',
      'createInverter',
      'deleteInverter',
      'deleteInverterAttachment',
      'uploadInverterAttachment',
      'fetchGridAndPlantProtectionTypes',
    ]),
    isDisabled(e) {
      e.preventDefault();
    },
    onDeleteInverterAttachment(event) {
      this.deleteInverterAttachment({
        inverterId: this.inverter.id,
        attachmentId: event.attachmentId,
      });
    },
  },
  async mounted() {
    await this.fetchGridAndPlantProtectionTypes();

    if (!this.inverter && this.inverterNumber) {
      this.number = this.inverterNumber;
      this.fetchInverterByNumber(this.number);
    } else {
      this.initInverter();
      this.number = '_new';
    }
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadInverterAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style scoped lang="scss">
.inverterContacts {
  text-align: right;
}
.trash-contact {
  cursor: pointer;
}
</style>
